
$(document).ready(function() {
	scrollToTop();
})
function scrollToTop() {
var scrollTopBtn = $('.scroll-top');
	$(window).scroll(function() {
		var offsetTop = $('.join-community').offset().top;


		if ($(this).scrollTop() > offsetTop) {
				scrollTopBtn.fadeIn();
			} else {
				scrollTopBtn.fadeOut();
			}
	})


	scrollTopBtn.click(function() {
		$('html, body').animate({scrollTop : 0},400);
			return false;
	})
}

/*$('.menu-bar').click(function() {
    $('body').addClass('show-menu');
})*/